import { Stack } from "@chakra-ui/react";
import MonthlyReportHeader from "../components/MonthlyReportHeader";
import useConfigList from "../hooks/useConfigList";
import useNavigateNext from "../hooks/useNavigateNext";
import Regimens from "../components/Regimens";
import usePurpleBubblesText from "../hooks/usePurpleBubblesText";
import PurpleBubbleContent from "../components/PurpleBubbleContent";
import useUpdateReportDoc from "../hooks/useUpdateReportDoc";
import MonthlyReportNavButtons from "../components/MonthlyReportNavButtons";

export default () => {
  const items = useConfigList("mentalStrategies");
  const navNext = useNavigateNext();
  const updateReport = useUpdateReportDoc();
  const purpleBubbleContent = usePurpleBubblesText("Step-11");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateReport({ mentalStrategies: true });
    navNext();
  };

  const lists = [
    {
      fieldId: "mentalStrategyId",
      label: "Strategy",
      items,
      id: "mentalStrategies",
    },
  ];

  return (
    <Stack spacing={4}>
      <form onSubmit={handleSubmit}>
        <MonthlyReportHeader title="Mind Strategies" />
      </form>
      {purpleBubbleContent?.text && (
        <PurpleBubbleContent text={purpleBubbleContent.text} />
      )}
      <Regimens
        recordLabel="Mind Strategy"
        durationEnabled
        frequencyEnabled
        lists={lists}
      />
      <form onSubmit={handleSubmit}>
        <MonthlyReportNavButtons />
      </form>
    </Stack>
  );
};
