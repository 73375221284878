import { AddIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Select as SelectSearchable } from "chakra-react-select";
import { getAuth } from "firebase/auth";
import {
  deleteDoc,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { chain, map, orderBy, reverse } from "lodash";
import moment from "moment";
import { Fragment, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import ActionButtons from "./ActionButtons";
import useReportingForMonth from "../../hooks/useReportingForMonth";
// import useUpdateReportDoc from "../../hooks/useUpdateReportDoc";
import {
  AmountUnit,
  DurationUnit,
  FrequencyUnit,
  Method,
  Percentage,
  Regimen,
  SelectedFields,
  Usage,
  amountUnits,
  durationUnits,
  frequencyUnits,
  methods,
  percentages,
} from "../../types/Regimen";
import { contactEmail } from "../../utils/contactEmail";
import isRawDateValid from "../../utils/isRawDateValid";
import randomId from "../../utils/randomId";
import useRegimens from "../../utils/useRegimens";
import BasicDateStringPicker from "../BasicDateStringPicker";

export default ({
  lists,
  amountEnabled,
  frequencyEnabled,
  durationEnabled,
  recordLabel,
  methodEnabled,
  percentageEnabled,
  allowUpdate = true,
  alert,
}: {
  lists: {
    label: string;
    fieldId: string;
    items: { [itemId: string]: string };
    id: string;
  }[];
  amountEnabled?: boolean;
  frequencyEnabled?: boolean;
  methodEnabled?: boolean;
  durationEnabled?: boolean;
  percentageEnabled?: boolean;
  recordLabel: string;
  allowUpdate?: boolean;
  alert?: string;
}) => {
  const modal = useDisclosure();
  const [selectedFields, setSelectedFields] = useState<SelectedFields>({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [frequency, setFrequency] = useState("");
  const [amount, setAmount] = useState("");
  const [duration, setDuration] = useState("");
  const [method, setMethod] = useState<Method>();
  const [frequencyUnit, setFrequencyUnit] = useState<FrequencyUnit>();
  const [amountUnit, setAmountUnit] = useState<AmountUnit>();
  const [durationUnit, setDurationUnit] = useState<DurationUnit>();
  const [percentage, setPercentage] = useState<Percentage>();
  const records = useRegimens(lists[0].id);
  const [editingId, setEditingId] = useState<string>();
  const toast = useToast();
  const [confirmDelete, setConfirmDelete] = useState<{
    recordId: string;
    usageIndex: number;
  }>();
  const deleteCancelRef = useRef<any>();
  const [confirmEndId, setConfirmEndId] = useState<string>();
  const location = useLocation();
  const { month, year } = useReportingForMonth();
  // const updateReport = useUpdateReportDoc();
  const [missingVisible, setMissingVisible] = useState(false);

  const reportingFor = location.pathname.split("/")[2];
  const reportingForStart = reportingFor + "-01";
  const reportingForEnd = reportingFor + "-31";

  const closeConfirmDelete = () => setConfirmDelete(undefined);
  const closeConfirmEnd = () => setConfirmEndId(undefined);

  function clear() {
    setStartDate("");
    setEndDate("");
    setSelectedFields({});
    setFrequency("");
    setFrequencyUnit(undefined);
    setAmount("");
    setDuration("");
    setMethod(undefined);
    setAmountUnit(undefined);
    setDurationUnit(undefined);
    setEditingId(undefined);
    setPercentage(undefined);
    setMissingVisible(false);
  }

  //keep the code insce modal.onClose
  const modalOnClose = modal.onClose;
  modal.onClose = () => {
    clear();
    modalOnClose();
  };

  function onSubmit() {
    const editingRecord = editingId
      ? records.find((r) => r.id === editingId)
      : undefined;

    const uid = getAuth().currentUser?.uid;
    if (!uid) return;

    const newRecord: Regimen = editingRecord
      ? { ...editingRecord }
      : {
          startDate,
          id: randomId(),
          primaryId: selectedFields[lists[0].fieldId],
          list: lists[0].id,
          usages: [],
          uid,
        };

    if (startDate && !isRawDateValid(startDate)) {
      return toast({
        status: "error",
        title: "Error",
        description:
          "You have entered an invalid Start Date. Please enter in the format YYYY-MM-DD.",
        isClosable: true,
      });
    }
    // console.log("startDate valid?:", isRawDateValid(startDate));
    // console.log("startDate submi:", startDate);
    // console.log("reportingForEnd:", reportingForEnd);
    // console.log("recordLabel:", recordLabel);

    if (endDate && !isRawDateValid(endDate)) {
      return toast({
        status: "error",
        title: "Error",
        description:
          "You have entered an invalid End Date. Please enter in the format YYYY-MM-DD.",
        isClosable: true,
      });
    }

    if (startDate > reportingForEnd) {
      return toast({
        status: "error",
        title: "Error",
        description: "Start date must be in or before the current month.",
      });
    }

    const usage: Usage = {
      fields: selectedFields,
      startDate,
    };

    if (frequencyEnabled) {
      const frequencyInt = parseInt(frequency);
      if (!frequencyUnit || isNaN(frequencyInt) || frequencyInt === 0) {
        return toast({
          status: "error",
          title: "Error",
          description: "Invalid Frequency",
        });
      }
      usage.frequency = frequencyInt;
      usage.frequencyUnit = frequencyUnit;
      if (frequencyUnit === "one_time") {
        const endDate = new Date(startDate + "T00:00:00");
        const durationInt = parseInt(duration);
        //Subtract one from the days because the start date counts as the first day
        if (durationUnit === "days" && durationInt > 1) {
          endDate.setDate(endDate.getDate() + durationInt - 1);
        }
        newRecord.endDate = moment(endDate, "YYYY-MM-DD").format("YYYY-MM-DD");
      }
    }

    if (percentageEnabled) {
      if (!percentage) {
        return toast({
          status: "error",
          title: "Error",
          description: "Invalid Percentage",
        });
      }
      usage.percentage = percentage;
    }

    if (amountEnabled) {
      const amountFloat = parseFloat(amount);
      if (!amountUnit || isNaN(amountFloat) || amountFloat <= 0) {
        return toast({
          status: "error",
          title: "Error",
          description: "Invalid Amount",
        });
      }
      usage.amount = amountFloat;
      usage.amountUnit = amountUnit;
    }

    if (durationEnabled) {
      const durationInt = parseInt(duration);
      if (!durationUnit || isNaN(durationInt) || durationInt === 0) {
        return toast({
          status: "error",
          title: "Error",
          description: "Invalid Duration",
        });
      }
      usage.duration = durationInt;
      usage.durationUnit = durationUnit;
    }

    if (methodEnabled) {
      if (!method) {
        return toast({
          status: "error",
          title: "Error",
          description: "Invalid method",
        });
      }
      usage.method = method;
    }

    if (
      newRecord.usages.length > 0 &&
      newRecord.usages[newRecord.usages.length - 1].startDate ===
        usage.startDate
    ) {
      newRecord.usages[newRecord.usages.length - 1] = usage;
    } else newRecord.usages.push(usage);

    // console.log("newRecord", newRecord);
    setDoc(doc(getFirestore(), `regimens/${newRecord.id}`), newRecord);

    clear();
    modal.onClose();
  }

  const duplicateCheck = (regimenId: string) => {
    let isDuplicate = false;
    records.forEach((record) => {
      if (
        record.usages.at(-1)?.fields?.supplementId === regimenId &&
        record.endDate === undefined
      ) {
        isDuplicate = true;
      }
    });
    return isDuplicate;
  };
  // console.log("reporting for:", reportingFor);
  // console.log("reporting for end:", reportingForEnd);
  // console.log("start date:", startDate);
  // console.log("selectedFields:", selectedFields);

  return (
    <>
      <Stack spacing={4}>
        {!!alert && <Alert status="info">{alert}</Alert>}
        <Box>
          <Button
            rightIcon={<AddIcon />}
            onClick={() => {
              modal.onOpen();
            }}
          >
            Add {recordLabel}
          </Button>
        </Box>
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Start</Th>
                <Th>End</Th>
                {lists.map((list) => {
                  return <Th key={`th-${list.fieldId}`}>{list.label}</Th>;
                })}
                {percentageEnabled && <Th>Percentage</Th>}
                {amountEnabled && <Th>Amount</Th>}
                {durationEnabled && <Th>Duration</Th>}
                {frequencyEnabled && <Th>Frequency</Th>}
                {methodEnabled && <Th>Method</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {orderBy(
                // The filter was added to remove any diets which were hidden from the admin/diets section
                // Without the filter, previous records using now hidden diets would display with start and end date, but no diet name
                records.filter(
                  (record) => lists[0].items[record.primaryId] !== undefined
                )
              )
                // First: Sort asc by current regemins
                // Second: Sort asc by completed regemins
                .sort((a, b) => {
                  if (a.endDate === undefined && b.endDate !== undefined)
                    return -1;
                  if (a.endDate !== undefined && b.endDate === undefined)
                    return 1;
                  const listA = lists[0].items[a.primaryId];
                  const listB = lists[0].items[b.primaryId];
                  return listA.localeCompare(listB);
                })
                .map((record) => {
                  const displayUsages = reverse([...record.usages]);
                  return (
                    <Fragment key={record.id}>
                      {displayUsages.map((usage, usageIndex) => {
                        return (
                          <Tr key={record.id + "-" + usageIndex}>
                            <ActionButtons
                              usageIndex={usageIndex}
                              record={record}
                              usage={usage}
                              allowUpdate={allowUpdate}
                              reportingForStart={reportingForStart}
                              setEditingId={setEditingId}
                              setStartDate={setStartDate}
                              setFrequency={setFrequency}
                              setFrequencyUnit={setFrequencyUnit}
                              setAmount={setAmount}
                              setAmountUnit={setAmountUnit}
                              setMethod={setMethod}
                              setSelectedFields={setSelectedFields}
                              setDuration={setDuration}
                              setDurationUnit={setDurationUnit}
                              setPercentage={setPercentage}
                              modal={modal}
                              setConfirmEndId={setConfirmEndId}
                              setConfirmDelete={setConfirmDelete}
                            />
                            <Td>
                              {usage.startDate
                                ? moment(usage.startDate, "YYYY-MM-DD").format(
                                    "YYYY-MM-DD"
                                  )
                                : `Before ${month}/${year}`}
                            </Td>
                            <Td>
                              {usageIndex === 0
                                ? record.endDate
                                  ? moment(record.endDate, "YYYY-MM-DD").format(
                                      "YYYY-MM-DD"
                                    )
                                  : `Current`
                                : moment(
                                    displayUsages[usageIndex - 1].startDate
                                  ).format("YYYY-MM-DD")}
                            </Td>
                            {lists.map((list) => {
                              return (
                                <Td key={`${record.id}-${list.fieldId}`}>
                                  {list.items[usage.fields[list.fieldId]]}
                                </Td>
                              );
                            })}
                            {percentageEnabled && (
                              <Td>
                                {usage.percentage &&
                                  percentages[usage.percentage]}
                              </Td>
                            )}
                            {amountEnabled && (
                              <Td>
                                {usage.amount}{" "}
                                {usage.amountUnit &&
                                  amountUnits[usage.amountUnit]}
                              </Td>
                            )}
                            {durationEnabled && (
                              <Td>
                                {usage.duration}{" "}
                                {usage.durationUnit &&
                                  durationUnits[usage.durationUnit]}
                              </Td>
                            )}
                            {frequencyEnabled && (
                              <Td>
                                {usage.frequency}x/
                                {usage.frequencyUnit &&
                                  frequencyUnits[usage.frequencyUnit]}
                              </Td>
                            )}
                            {methodEnabled && (
                              <Td>
                                {usage.method ? methods[usage.method] : "N/A"}
                              </Td>
                            )}
                          </Tr>
                        );
                      })}
                    </Fragment>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
      <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
        <ModalOverlay />
        <ModalContent
          as="form"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <ModalHeader>
            {editingId ? "Edit" : "Add"} {recordLabel}
          </ModalHeader>
          <ModalCloseButton onClick={() => clear()} />
          <ModalBody>
            <Stack spacing={3}>
              {lists.map((list, index) => {
                let placeholder = `Choose ${list.label}`;
                const options = chain(list.items)
                  .map((label, id) => {
                    return { label, id };
                  })
                  .orderBy((item) => item.label.toLowerCase(), "asc")
                  .value()
                  .map(({ id, label }) => {
                    let isSelected = false;
                    if (selectedFields[list.fieldId] === id) {
                      isSelected = true;
                      placeholder = label;
                    }
                    return {
                      label: label,
                      value: id,
                      isSelected: isSelected,
                    };
                  });
                return (
                  <FormControl key={list.fieldId} isRequired>
                    <FormLabel>{list.label}</FormLabel>
                    <SelectSearchable
                      options={options}
                      isDisabled={!!editingId && index === 0}
                      required={!!editingId && index === 0}
                      isOptionSelected={(
                        option: { [key: string]: any },
                        selectValue: ReadonlyArray<{ [key: string]: any }>
                      ) => option.isSelected}
                      filterOption={(
                        option: { [key: string]: any },
                        inputValue: string
                      ) =>
                        option.label
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      }
                      onChange={(option: {
                        label: string;
                        value: string;
                        isSelcted: boolean;
                      }) => {
                        const isDuplicate = duplicateCheck(option.value);
                        if (isDuplicate) {
                          const allowDuplicate = window.confirm(
                            `${option.label} is currently active in your list. Are you sure you want to add it?`
                          );
                          if (!allowDuplicate) {
                            modal.onClose();
                            clear();
                            return;
                          }
                        }
                        setSelectedFields({
                          ...selectedFields,
                          [list.fieldId]: option.value,
                        });
                      }}
                      placeholder={placeholder}
                    />
                    {missingVisible ? (
                      <Text fontSize="xs">
                        If your option is missing, please message us at{" "}
                        <a href={`mailto:${contactEmail}`}>{contactEmail}</a>{" "}
                        and we will get it added to the list.
                      </Text>
                    ) : (
                      <Button
                        size="xs"
                        variant="link"
                        onClick={() => setMissingVisible(true)}
                      >
                        Missing?
                      </Button>
                    )}
                  </FormControl>
                );
              })}

              <FormControl isRequired>
                <FormLabel>
                  {editingId ? "What date did you change?" : "Start Date"}
                </FormLabel>
                <BasicDateStringPicker
                  startYear={"1920"}
                  endYear={new Date().getFullYear().toString()}
                  dateString={startDate}
                  setDateString={setStartDate}
                />
              </FormControl>
              {percentageEnabled && (
                <FormControl isRequired>
                  <FormLabel>Percentage of Protocol followed</FormLabel>
                  <RadioGroup
                    value={percentage}
                    onChange={(value) => {
                      setPercentage(value as Percentage);
                    }}
                  >
                    <HStack spacing={2}>
                      {map(percentages, (label, percentage) => {
                        return (
                          <Radio key={percentage} value={percentage}>
                            {label}
                          </Radio>
                        );
                      })}
                    </HStack>
                  </RadioGroup>
                </FormControl>
              )}
              {amountEnabled && (
                <SimpleGrid columns={2} spacing={3}>
                  <FormControl isRequired>
                    <FormLabel>Amount</FormLabel>
                    <Input
                      required
                      type="number"
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      step="any"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Unit</FormLabel>
                    <Select
                      required
                      value={amountUnit || ""}
                      placeholder="Choose Unit"
                      onChange={(e) => {
                        setAmountUnit(e.target.value as AmountUnit);
                      }}
                    >
                      {chain(amountUnits)
                        .map((title, value) => {
                          return {
                            title,
                            value: value as AmountUnit,
                          };
                        })
                        .orderBy((item) => item.title.toLowerCase(), "asc")
                        .map(({ title, value }) => {
                          return (
                            <option value={value} key={value}>
                              {title}
                            </option>
                          );
                        })
                        .value()}
                    </Select>
                  </FormControl>
                </SimpleGrid>
              )}
              {durationEnabled && (
                <SimpleGrid columns={2} spacing={3}>
                  <FormControl isRequired>
                    <FormLabel>Duration</FormLabel>
                    <Input
                      required
                      type="number"
                      value={duration}
                      onChange={(e) => {
                        setDuration(e.target.value);
                      }}
                      step="any"
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Unit</FormLabel>
                    <Select
                      required
                      value={durationUnit || ""}
                      placeholder="Choose Unit"
                      onChange={(e) => {
                        setDurationUnit(e.target.value as DurationUnit);
                      }}
                    >
                      {map(durationUnits, (title, value) => {
                        return (
                          <option key={value} value={value}>
                            {title}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </SimpleGrid>
              )}
              {frequencyEnabled && (
                <SimpleGrid columns={2} spacing={3}>
                  <FormControl isRequired>
                    <FormLabel>Frequency</FormLabel>
                    <InputGroup>
                      <Input
                        required
                        type="number"
                        value={frequency}
                        onChange={(e) => {
                          setFrequency(e.target.value);
                        }}
                        step="any"
                      />
                      <InputRightAddon>x</InputRightAddon>
                    </InputGroup>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Per</FormLabel>
                    <Select
                      required
                      value={frequencyUnit || ""}
                      placeholder="Choose Unit"
                      onChange={(e) => {
                        setFrequencyUnit(e.target.value as FrequencyUnit);
                      }}
                    >
                      {map(frequencyUnits, (title, value) => {
                        return (
                          <option key={value} value={value}>
                            {title}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </SimpleGrid>
              )}
              {methodEnabled && (
                <SimpleGrid columns={2} spacing={3}>
                  <FormControl isRequired>
                    <FormLabel>Method</FormLabel>
                    <Select
                      required
                      value={method}
                      placeholder="Choose Method"
                      onChange={(e) => {
                        setMethod(e.target.value as Method);
                      }}
                    >
                      {chain(methods)
                        .map((title, value) => {
                          return { title, value };
                        })
                        .orderBy("title", "asc")
                        .map(({ title, value }) => {
                          return (
                            <option key={value} value={value}>
                              {title}
                            </option>
                          );
                        })
                        .value()}
                    </Select>
                  </FormControl>
                </SimpleGrid>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              colorScheme="gray"
              onClick={() => {
                modal.onClose();
                clear();
              }}
              mr={3}
            >
              Cancel
            </Button>
            <Button size="sm" type="submit">
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={!!confirmDelete}
        leastDestructiveRef={deleteCancelRef}
        onClose={closeConfirmDelete}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remove {recordLabel}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to remove this item?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme="gray"
                ref={deleteCancelRef}
                onClick={closeConfirmDelete}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  if (confirmDelete) {
                    const _record = records.find(
                      (r) => r.id === confirmDelete.recordId
                    );

                    if (_record) {
                      const record = { ..._record };
                      record.usages.splice(confirmDelete.usageIndex, 1);

                      const ref = doc(getFirestore(), `regimens/${record.id}`);

                      if (record.usages.length === 0) {
                        deleteDoc(ref);
                      } else {
                        setDoc(ref, record);
                      }
                    }
                  }

                  setConfirmDelete(undefined);
                }}
                ml={3}
              >
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal isOpen={!!confirmEndId} onClose={closeConfirmEnd}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Stop {recordLabel}</ModalHeader>

          <ModalBody>
            <Stack>
              <Text>On what date did you stop? (YYYY-MM-DD)</Text>
              <FormControl>
                <FormLabel>End Date</FormLabel>
                <BasicDateStringPicker
                  startYear={"1920"}
                  endYear={new Date().getFullYear().toString()}
                  dateString={endDate}
                  setDateString={setEndDate}
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" onClick={closeConfirmEnd}>
              Cancel
            </Button>
            <Button
              disabled={!endDate}
              colorScheme="red"
              onClick={() => {
                if (!isRawDateValid(endDate)) {
                  return toast({
                    status: "error",
                    title: "Error",
                    description:
                      "You have entered an invalid End Date. Please enter in the format YYYY-MM-DD.",
                    isClosable: true,
                  });
                }

                const formattedEndDate = moment(endDate, "YYYY-MM-DD");
                const currentMonthEnd = moment().endOf("month");

                if (formattedEndDate.isAfter(currentMonthEnd)) {
                  return toast({
                    status: "error",
                    title: "Error",
                    description:
                      "End date must be in or before the current month.",
                  });
                }

                const recordToEnd = records.find((r) => r.id === confirmEndId);
                if (!recordToEnd) {
                  return toast({
                    status: "error",
                    title: "Error",
                    description: "Could not find record to end.",
                  });
                }

                const lastUsageStartDate = moment(
                  recordToEnd.usages[recordToEnd.usages.length - 1].startDate,
                  "YYYY-MM-DD"
                );

                if (formattedEndDate.isBefore(lastUsageStartDate)) {
                  return toast({
                    status: "error",
                    title: "Error",
                    description: "End date must be after the start date.",
                  });
                }

                updateDoc(doc(getFirestore(), `regimens/${confirmEndId}`), {
                  endDate,
                });
                setConfirmEndId(undefined);
                setEndDate("");
              }}
              ml={3}
            >
              Stop
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
