import { useMemo } from 'react'
import { Table, Tbody, Text, Th, Thead, Tr, Td } from '@chakra-ui/react'
import MedicalConditionRow from './MedicalConditionRow'
import FirestoreUser, { MedicalData } from '../../../types/FirestoreUser'
import { calculateAge } from '../../../utils/calculateAge'
import {
  variousConditions,
  neurologicalConditions,
  heartConditions,
  gastroConditions,
  endocrineConditions,
  respiratoryConditions,
  viralConditions,
  fungalConditions,
  parasitesConditions,
} from './conditions'

interface Props {
  uid: string
  firestoreUser: FirestoreUser
  medicalHistory: MedicalData[]
  setMedicalHistory: (data: MedicalData[]) => void
}

interface ConditionSectionProps {
  title: string
  conditions: { name: string }[]
  userAge: number | undefined
  uid: string
  medicalHistory: MedicalData[]
  setMedicalHistory: (data: MedicalData[]) => void
}

function ConditionSection({
  title,
  conditions,
  userAge,
  uid,
  medicalHistory,
  setMedicalHistory,
}: ConditionSectionProps) {
  return (
    <>
      <Tr>
        <Td colSpan={6}>
          <Text fontWeight="bold">{title}</Text>
        </Td>
      </Tr>
      {conditions
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((condition) => (
          <MedicalConditionRow
            key={condition.name}
            condition={condition.name}
            age={userAge}
            uid={uid}
            medicalHistory={medicalHistory}
            setMedicalHistory={setMedicalHistory}
          />
        ))}
    </>
  )
}

export default function MedicalConditions({
  uid,
  firestoreUser,
  medicalHistory,
  setMedicalHistory,
}: Props) {
  const userAge = useMemo(() => {
    const birthDate = firestoreUser.profile?.birthDate
    if (!birthDate) return undefined
    return calculateAge(birthDate)
  }, [firestoreUser])

  return (
    <>
      <Table variant="simple" mt={4}>
        <Thead>
          <Tr>
            <Th textAlign="left">Condition</Th>
            <Th textAlign="left">Have/Had Condition</Th>
            <Th textAlign="left">Age Diagnosed</Th>
            <Th textAlign="left">Age Ended (Blank if current)</Th>
            <Th textAlign="left">Frequency</Th>
            <Th textAlign="left">Estimated total years</Th>
          </Tr>
        </Thead>
        <Tbody>
          <ConditionSection
            title="Various"
            conditions={variousConditions}
            userAge={userAge}
            uid={uid}
            medicalHistory={medicalHistory}
            setMedicalHistory={setMedicalHistory}
          />
          <ConditionSection
            title="Neurological History"
            conditions={neurologicalConditions}
            userAge={userAge}
            uid={uid}
            medicalHistory={medicalHistory}
            setMedicalHistory={setMedicalHistory}
          />
          <ConditionSection
            title="Heart (Cardiobasculr) disease history"
            conditions={heartConditions}
            userAge={userAge}
            uid={uid}
            medicalHistory={medicalHistory}
            setMedicalHistory={setMedicalHistory}
          />
          <ConditionSection
            title="Gastrointestinal history"
            conditions={gastroConditions}
            userAge={userAge}
            uid={uid}
            medicalHistory={medicalHistory}
            setMedicalHistory={setMedicalHistory}
          />
          <ConditionSection
            title="Endocrine System"
            conditions={endocrineConditions}
            userAge={userAge}
            uid={uid}
            medicalHistory={medicalHistory}
            setMedicalHistory={setMedicalHistory}
          />
          <ConditionSection
            title="Respiratory"
            conditions={respiratoryConditions}
            userAge={userAge}
            uid={uid}
            medicalHistory={medicalHistory}
            setMedicalHistory={setMedicalHistory}
          />
          <ConditionSection
            title="Known Common Viral or Baterial Infections"
            conditions={viralConditions}
            userAge={userAge}
            uid={uid}
            medicalHistory={medicalHistory}
            setMedicalHistory={setMedicalHistory}
          />
          <ConditionSection
            title="Fungal Infections"
            conditions={fungalConditions}
            userAge={userAge}
            uid={uid}
            medicalHistory={medicalHistory}
            setMedicalHistory={setMedicalHistory}
          />
          <ConditionSection
            title="Parasites"
            conditions={parasitesConditions}
            userAge={userAge}
            uid={uid}
            medicalHistory={medicalHistory}
            setMedicalHistory={setMedicalHistory}
          />
        </Tbody>
      </Table>
    </>
  )
}
