import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'
import {
  CollectionReference,
  QueryDocumentSnapshot,
  collection,
  collectionGroup,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore'
import { orderBy } from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import UsersTable from '../components/UsersTable'
import useHandleError from '../hooks/useHandleError'
import { MonthlyReport } from '../store/useStore'
import FirestoreUser from '../types/FirestoreUser'

export default () => {
  const [month, setMonth] = useState(moment().format('YYYY-MM'))
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{
    month: string
    complete: QueryDocumentSnapshot<FirestoreUser>[]
    incomplete: QueryDocumentSnapshot<FirestoreUser>[]
  }>()
  const [selectedButton, setSelectedButton] = useState<string | null>(null)
  const handleError = useHandleError()

  const handleButtonClick = (button: string) => {
    if (selectedButton === button) {
      setSelectedButton(null)
      setMonth(moment().format('YYYY-MM'))
    } else {
      setSelectedButton(button)
      switch (button) {
        case 'current':
          setMonth(moment().format('YYYY-MM'))
          break
        case 'last':
          setMonth(moment().subtract(1, 'month').format('YYYY-MM'))
          break
        case 'last3':
          setMonth(moment().subtract(3, 'months').format('YYYY-MM'))
          break
        case 'last6':
          setMonth(moment().subtract(6, 'months').format('YYYY-MM'))
          break
      }
    }
  }

  return (
    <Stack spacing={6}>
      <Heading>Completion</Heading>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          setLoading(true)
          getCompletion(month, selectedButton)
            .then((data) => setData({ ...data, month }))
            .catch(handleError)
            .finally(() => setLoading(false))
        }}
      >
        <Stack maxW="container.sm">
          <FormControl isRequired>
            <FormLabel>Enter month in YYYY-MM format</FormLabel>
            <Input
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              placeholder="Month"
              isDisabled={selectedButton !== null}
            />
          </FormControl>
          <Stack direction="row" spacing={4}>
            <Button
              variant={selectedButton === 'current' ? 'solid' : 'outline'}
              onClick={() => handleButtonClick('current')}
            >
              Current month
            </Button>
            <Button
              variant={selectedButton === 'last' ? 'solid' : 'outline'}
              onClick={() => handleButtonClick('last')}
            >
              Last month
            </Button>
            <Button
              variant={selectedButton === 'last3' ? 'solid' : 'outline'}
              onClick={() => handleButtonClick('last3')}
            >
              Last three months
            </Button>
            <Button
              variant={selectedButton === 'last6' ? 'solid' : 'outline'}
              onClick={() => handleButtonClick('last6')}
            >
              Last six months
            </Button>
          </Stack>
          <Box>
            <Button
              isDisabled={loading || !month.length}
              isLoading={loading}
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </Stack>
      </form>
      {data && (
        <Stack spacing={4}>
          <Heading fontSize="lg">
            Results for:{' '}
            {selectedButton === 'last3'
              ? `${data.month} -> ${moment(data.month)
                  .add(2, 'months')
                  .format('YYYY-MM')}`
              : selectedButton === 'last6'
              ? `${data.month} -> ${moment(data.month)
                  .add(5, 'months')
                  .format('YYYY-MM')}`
              : data.month}
          </Heading>
          <Stack spacing={2}>
            <Heading fontSize="md">Complete ({data.complete.length}):</Heading>
            <UsersTable docs={data.complete} lastNameFirst />
            <Text>{data.complete.map((d) => d.get('email')).join(',')}</Text>
          </Stack>
          <Stack spacing={2}>
            <Heading fontSize="md">
              Incomplete ({data.incomplete.length}):
            </Heading>
            <UsersTable docs={data.incomplete} lastNameFirst />
            <Text>{data.incomplete.map((d) => d.get('email')).join(',')}</Text>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

async function getCompletion(month: string, selectedButton: string | null) {
  const months = [month]
  if (selectedButton === 'last3') {
    months.push(
      moment(month).subtract(1, 'month').format('YYYY-MM'),
      moment(month).subtract(2, 'months').format('YYYY-MM')
    )
  } else if (selectedButton === 'last6') {
    for (let i = 1; i <= 5; i++) {
      months.push(moment(month).subtract(i, 'month').format('YYYY-MM'))
    }
  }

  const patientsSnap = await getDocs(
    query(
      collection(getFirestore(), 'users') as CollectionReference<FirestoreUser>,
      where('roles', 'array-contains', 'patient'),
      where('profile', '!=', null)
    )
  )

  const patients = patientsSnap.docs.filter((doc) => {
    const user = doc.data()
    const diagnosis = user.profile?.diagnosis
    return (
      diagnosis === 'als' || diagnosis === 'als-probable' || diagnosis === 'pls'
    )
  })

  const reports = await Promise.all(
    months.map((m) =>
      getDocs(
        query(
          collectionGroup(
            getFirestore(),
            'reports'
          ) as CollectionReference<MonthlyReport>,
          where('id', '==', m)
        )
      )
    )
  )

  const uidsWithCompleteReports: string[] = []

  reports.forEach((reportSnap) => {
    reportSnap.forEach((doc) => {
      const report = doc.data()
      const uid = doc.ref.parent.parent?.id
      if (!uid) {
        throw new Error(
          'Could not get uid for report doc with path ' + doc.ref.path
        )
      }

      let complete = true
      if (report.alsfrs?.score === undefined) {
        complete = false
      }
      if (report.alssq?.score === undefined) {
        complete = false
      }
      if (!report.clinicalObservations) {
        complete = false
      }
      if (!report.diets) {
        complete = false
      }
      if (!report.mentalStrategies) {
        complete = false
      }
      if (!report.nonSupplementMedications) {
        complete = false
      }
      if (
        !report.panas?.modifiedPanas?.complete &&
        !report.panas?.main?.complete
      ) {
        complete = false
      }
      if (!report.physicalStrategies) {
        complete = false
      }
      if (!report.stool?.stool?.complete) {
        complete = false
      }
      if (!report.stool?.urine?.complete) {
        complete = false
      }
      if (!report.supplements) {
        complete = false
      }
      if (report.vitalStats?.weight === undefined) {
        complete = false
      }

      if (complete) {
        uidsWithCompleteReports.push(uid)
      }
    })
  })

  const emails: {
    complete: string[]
    incomplete: string[]
  } = { complete: [], incomplete: [] }

  const docs: {
    complete: QueryDocumentSnapshot<FirestoreUser>[]
    incomplete: QueryDocumentSnapshot<FirestoreUser>[]
  } = {
    complete: [],
    incomplete: [],
  }

  patients.forEach((doc) => {
    const email = doc.get('email')

    if (uidsWithCompleteReports.includes(doc.id)) {
      emails.complete.push(email)
      docs.complete.push(doc)
    } else {
      emails.incomplete.push(email)
      docs.incomplete.push(doc)
    }
  })

  docs.complete = orderBy(docs.complete, (doc) => {
    const data = doc.data()
    return `${data.profile?.searchLastName}, ${data.profile?.searchFirstName}`
  })
  docs.incomplete = orderBy(docs.incomplete, (doc) => {
    const data = doc.data()
    return `${data.profile?.searchLastName}, ${data.profile?.searchFirstName}`
  })

  return {
    ...docs,
    month:
      selectedButton === 'last3'
        ? `${months[2]} - ${months[0]}`
        : selectedButton === 'last6'
        ? `${months[5]} - ${months[0]}`
        : month,
  }
}
