import { Table, Thead, Tbody, Tr, Th } from '@chakra-ui/react'
import FirestoreUser, { SurgeryData } from '../../../types/FirestoreUser'
import SurgeryHistoryRow from './SurgeryHistoryRow'

const surgeries = [
  'Back/Neck surgery',
  'Limb/joint surgery - upper',
  'Limb/joint surgery - lower',
  'Appendix removed',
  'Gall bladder removed',
  'Spleen removed',
  'Tonsils removed',
  'Adenoids removed',
  'Eye surgery',
  'Cancer related surgery',
  'Heart surgery stent/bypass/pacemaker/etc.',
  'Some Lymph nodes removed',
  'Organ replacement',
  'Other',
]

interface Props {
  uid: string
  firestoreUser: FirestoreUser
  surgeryHistory: SurgeryData[]
  setSurgeryHistory: (data: SurgeryData[]) => void
}

export default function SurgeryHistory({
  uid,
  firestoreUser,
  surgeryHistory,
  setSurgeryHistory,
}: Props) {
  return (
    <Table variant="simple" mt={4} size="sm">
      <Thead>
        <Tr>
          <Th>Surgery</Th>
          <Th>Had Surgery</Th>
          <Th>Age of 1st Surgery</Th>
          <Th>Age of 2nd Surgery</Th>
          <Th>Age of 3rd Surgery</Th>
          <Th>Age of 4th Surgery</Th>
        </Tr>
      </Thead>
      <Tbody>
        {surgeries.map((surgery) => (
          <SurgeryHistoryRow
            key={surgery}
            surgery={surgery}
            uid={uid}
            surgeryHistory={surgeryHistory}
            setSurgeryHistory={setSurgeryHistory}
          />
        ))}
      </Tbody>
    </Table>
  )
}
