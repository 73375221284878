import { Tr, Td, Checkbox, Input } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { ChildhoodVaccineData } from '../../../types/FirestoreUser'

interface Props {
  vaccine: string
  uid: string
  childhoodVaccineHistory: ChildhoodVaccineData[]
  setChildhoodVaccineHistory: (data: ChildhoodVaccineData[]) => void
}

export default function ChildhoodVaccineHistoryRow({
  vaccine,
  uid,
  childhoodVaccineHistory,
  setChildhoodVaccineHistory,
}: Props) {
  const [isChecked, setIsChecked] = useState(false)
  const [estimatedNumber, setEstimatedNumber] = useState<number | undefined>()
  const [ageOne, setAgeOne] = useState<number | undefined>()
  const [ageTwo, setAgeTwo] = useState<number | undefined>()
  const [ageThree, setAgeThree] = useState<number | undefined>()

  useEffect(() => {
    const existingCondition = childhoodVaccineHistory.find((item) => {
      return item.vaccine === vaccine
    })
    if (existingCondition) {
      setIsChecked(true)
      setEstimatedNumber(existingCondition.estimatedNumber || undefined)
      setAgeOne(existingCondition.ageOne || undefined)
      setAgeTwo(existingCondition.ageTwo || undefined)
      setAgeThree(existingCondition.ageThree || undefined)
    } else {
      setIsChecked(false)
      setEstimatedNumber(undefined)
      setAgeOne(undefined)
      setAgeTwo(undefined)
      setAgeThree(undefined)
    }
  }, [vaccine, childhoodVaccineHistory])

  useEffect(() => {
    const updatedHistory = childhoodVaccineHistory.filter(
      (item) => item.vaccine !== vaccine
    )

    if (isChecked) {
      updatedHistory.push({
        id: vaccine,
        vaccine,
        estimatedNumber: estimatedNumber === undefined ? null : estimatedNumber,
        ageOne: ageOne === undefined ? null : ageOne,
        ageTwo: ageTwo === undefined ? null : ageTwo,
        ageThree: ageThree === undefined ? null : ageThree,
      })
    }

    setChildhoodVaccineHistory(updatedHistory)
  }, [isChecked, ageOne, ageTwo, ageThree, estimatedNumber])

  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const checked = e.target.checked
    setIsChecked(checked)
    if (!checked) {
      setEstimatedNumber(undefined)
      setAgeOne(undefined)
      setAgeTwo(undefined)
      setAgeThree(undefined)
    }
  }

  return (
    <Tr>
      <Td>{vaccine}</Td>
      <Td textAlign="left">
        <Checkbox isChecked={isChecked} onChange={handleCheckboxChange} />
      </Td>
      <Td>
        <Input
          size="sm"
          type="number"
          placeholder={`Age`}
          isDisabled={!isChecked}
          value={estimatedNumber ?? ''}
          onChange={(e) => {
            const value = e.target.value
            setEstimatedNumber(value === '' ? undefined : Number(value))
          }}
        />
      </Td>
      <Td>
        <Input
          size="sm"
          type="number"
          placeholder={`Age`}
          isDisabled={!isChecked}
          value={ageOne ?? ''}
          onChange={(e) => {
            const value = e.target.value
            setAgeOne(value === '' ? undefined : Number(value))
          }}
        />
      </Td>
      <Td>
        <Input
          size="sm"
          type="number"
          placeholder={`Age`}
          isDisabled={!isChecked}
          value={ageTwo ?? ''}
          onChange={(e) => {
            const value = e.target.value
            setAgeTwo(value === '' ? undefined : Number(value))
          }}
        />
      </Td>
      <Td>
        <Input
          size="sm"
          type="number"
          placeholder={`Age`}
          isDisabled={!isChecked}
          value={ageThree ?? ''}
          onChange={(e) => {
            const value = e.target.value
            setAgeThree(value === '' ? undefined : Number(value))
          }}
        />
      </Td>
    </Tr>
  )
}
