import { Table, Thead, Tbody, Tr, Th } from '@chakra-ui/react'
import FirestoreUser, {
  ChildhoodVaccineData,
} from '../../../types/FirestoreUser'
import ChildhoodVaccineHistoryRow from './ChildhoodVaccineHistoryRow'

const vaccines = [
  'DTP or DTaP Diptheria/Pertusses/Tetanus/',
  'Diptheria',
  'Tetanus',
  'Pertussis',
  'Polio (IPV or other polio)',
  'MMR Measles/Mumps/Rubella',
  'Measles',
  'Mumps',
  'Rubella',
  'Smallpox',
  'VAR Varicella or Chicken Pox',
  'Hepatitis A',
  'Hepatitis B',
  'Hib Haemophilus Influeza Type B',
  'RSV Rotavirus',
  'Meningacoccal Type B',
  'HPV Human Papilloma Virus',
  'PCV Pneumococcal conjugate',
  'Flu/Influenza',
  'Covid19',
  'Other',
]

interface Props {
  uid: string
  firestoreUser: FirestoreUser
  childhoodVaccineHistory: ChildhoodVaccineData[]
  setChildhoodVaccineHistory: (data: ChildhoodVaccineData[]) => void
}

export default function ChildhoodVaccineHistory({
  uid,
  firestoreUser,
  childhoodVaccineHistory,
  setChildhoodVaccineHistory,
}: Props) {
  return (
    <Table variant="simple" mt={4} size="sm">
      <Thead>
        <Tr>
          <Th>Childhood Vaccine</Th>
          <Th>Had Vaccine</Th>
          <Th>Estimated # of vaccines, 5 years and under</Th>
          <Th>Age followup Booster 1</Th>
          <Th>Age followup Booster 2</Th>
          <Th>Age followup Booster 3</Th>
        </Tr>
      </Thead>
      <Tbody>
        {vaccines.map((vaccine) => (
          <ChildhoodVaccineHistoryRow
            key={vaccine}
            vaccine={vaccine}
            uid={uid}
            childhoodVaccineHistory={childhoodVaccineHistory}
            setChildhoodVaccineHistory={setChildhoodVaccineHistory}
          />
        ))}
      </Tbody>
    </Table>
  )
}
