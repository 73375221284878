import {
  AccordionItem,
  AccordionButton,
  Box,
  Heading,
  AccordionIcon,
  AccordionPanel,
  Button,
} from '@chakra-ui/react'

const SaveButton = ({
  isSaving,
  onClick,
}: {
  isSaving: boolean
  onClick: () => void
}) => (
  <Button size="lg" isLoading={isSaving} onClick={onClick}>
    Save
  </Button>
)

export default function HistoryAccordionItem({
  title,
  isSaving,
  onSave,
  children,
}: {
  title: string
  isSaving: boolean
  onSave: () => void
  children: React.ReactNode
}) {
  return (
    <AccordionItem>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          <Heading as="h2" size="md">
            {title}
          </Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <Box mb={4}>
          <SaveButton isSaving={isSaving} onClick={onSave} />
        </Box>
        {children}
        <Box mt={4}>
          <SaveButton isSaving={isSaving} onClick={onSave} />
        </Box>
      </AccordionPanel>
    </AccordionItem>
  )
}
