import { Box, Stack } from "@chakra-ui/react";
import MonthlyReportHeader from "../../components/MonthlyReportHeader";
import useConfigList from "../../hooks/useConfigList";
import Regimens from "../../components/Regimens";
import useNavigateNext from "../../hooks/useNavigateNext";
import WaterQuestions from "./WaterQuestions";
import MacroQuestions from "./MacroQuestions";
import CarbDietQuestions from "./CarbDietQuestions";
import DairyQuestions from "./DairyQuestions";
import GrainQuestions from "./GrainQuestions";
import usePurpleBubblesText from "../../hooks/usePurpleBubblesText";
import PurpleBubbleContent from "../../components/PurpleBubbleContent";
import useUpdateReportDoc from "../../hooks/useUpdateReportDoc";
import MonthlyReportNavButtons from "../../components/MonthlyReportNavButtons";

export default () => {
  const items = useConfigList("diets");
  const navNext = useNavigateNext();
  const updateReport = useUpdateReportDoc();
  const purpleBubbleContent = usePurpleBubblesText("Step-7");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateReport({ diets: true });
    navNext();
  };

  const renderQuestionSection = (Component: React.FC) => (
    <>
      <Box mb={4} />
      <Component />
    </>
  );

  const lists = [
    {
      fieldId: "dietId",
      label: "Diet",
      items,
      id: "diets",
    },
  ];

  return (
    <Stack spacing={4}>
      <form onSubmit={handleSubmit}>
        <Box mb={4}>
          <MonthlyReportHeader title="Diet" />
        </Box>
        {purpleBubbleContent?.text && (
          <PurpleBubbleContent text={purpleBubbleContent.text} />
        )}
        {renderQuestionSection(WaterQuestions)}
        {renderQuestionSection(MacroQuestions)}
        {renderQuestionSection(CarbDietQuestions)}
        {renderQuestionSection(DairyQuestions)}
        {renderQuestionSection(GrainQuestions)}
      </form>

      <Regimens recordLabel="Diet" lists={lists} allowUpdate={false} />
      <form onSubmit={handleSubmit}>
        <MonthlyReportNavButtons />
      </form>
    </Stack>
  );
};
