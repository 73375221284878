import { Table, Thead, Tbody, Tr, Th } from '@chakra-ui/react'
import FirestoreUser, { InjuryData } from '../../../types/FirestoreUser'
import InjuryHistoryRow from './InjuryHistoryRow'

interface InjuryHistoryProps {
  uid: string
  firestoreUser: FirestoreUser
  injuryHistory: InjuryData[]
  setInjuryHistory: (data: InjuryData[]) => void
}

const injuries = [
  'TBI Traumatic Brain Injury - Mild (Concussion)',
  'TBI Traumatic Brain Injury - Severe',
  'Spine injury - neck',
  'Spine injury - upper and/or mid back',
  'Spine injury - lower back',
  'Shoulder',
  'Arm/elbow/wrist/fingers',
  'Hip/femur/knee/lower leg/ankle/foot',
  'Chest/Abdomen',
]

export default function InjuryHistory({
  uid,
  firestoreUser,
  injuryHistory,
  setInjuryHistory,
}: InjuryHistoryProps) {
  return (
    <Table variant="simple" mt={4} size="sm">
      <Thead>
        <Tr>
          <Th>Injury</Th>
          <Th>Been Injured</Th>
          <Th>Age 1st Injury</Th>
          <Th>Age 2nd Injury</Th>
          <Th>Age 3rd Injury</Th>
          <Th>Age 4th Injury</Th>
        </Tr>
      </Thead>
      <Tbody>
        {injuries.map((injury) => (
          <InjuryHistoryRow
            key={injury}
            injury={injury}
            uid={uid}
            injuryHistory={injuryHistory}
            setInjuryHistory={setInjuryHistory}
          />
        ))}
      </Tbody>
    </Table>
  )
}
