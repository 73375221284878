import { getAuth } from 'firebase/auth'
import { doc, getFirestore, getDoc, updateDoc } from 'firebase/firestore'
import { useEffect, useState, useRef } from 'react'
import {
  Button,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Flex,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import PrescriptionModal from './PrescriptionModal'
import DeletePrescriptionDialog from './DeletePrescriptionDialog'
import { PrescriptionData } from '../../../types/FirestoreUser'
import FirestoreUser from '../../../types/FirestoreUser'

interface Props {
  uid: string
  firestoreUser: FirestoreUser
}

export default function PrescriptionHistory({ uid, firestoreUser }: Props) {
  const [prescriptionList, setPrescriptionList] = useState<
    Record<string, string>
  >({})
  const [prescriptionData, setPrescriptionData] = useState<PrescriptionData[]>(
    firestoreUser.medicalDetails?.prescriptionHistory || []
  )
  const [editIndex, setEditIndex] = useState<number | null>(null)
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)
  const toast = useToast()

  const [drug, setDrug] = useState('')
  const [category, setCategory] = useState('')
  const [ageStarted, setAgeStarted] = useState<number | null>(null)
  const [ageStopped, setAgeStopped] = useState<number | null>(null)
  const [usageType, setUsageType] = useState<'Intermittent' | 'Constant'>(
    'Intermittent'
  )
  const [totalYears, setTotalYears] = useState<number | null>(null)

  useEffect(() => {
    const fetchPrescriptionList = async () => {
      const ref = doc(getFirestore(), 'config', 'nonSupplementMedications')
      const snap = await getDoc(ref)
      if (snap.exists()) {
        const data = snap.data()
        const { value, archivedIds } = data
        const filteredValue = Object.keys(value)
          .filter((key) => !archivedIds.includes(key))
          .reduce((acc, key) => {
            acc[key] = value[key]
            return acc
          }, {} as Record<string, string>)
        setPrescriptionList(filteredValue)
      }
    }
    fetchPrescriptionList()
  }, [])

  const handleSavePrescription = async () => {
    if (!drug && !category) {
      return toast({
        status: 'error',
        title: 'Error',
        description: 'Drug or Category is required.',
        isClosable: true,
      })
    }

    if (ageStarted === null || ageStopped === null || totalYears === null) {
      return toast({
        status: 'error',
        title: 'Error',
        description: 'Age Started, Age Stopped, and Total Years are required.',
        isClosable: true,
      })
    }

    const newPrescription: PrescriptionData = {
      id:
        editIndex !== null
          ? prescriptionData[editIndex].id
          : new Date().toISOString(),
      drug: drug || undefined,
      category: category || undefined,
      ageStarted,
      ageStopped,
      usageType,
      totalYears,
    }

    const updatedPrescriptions =
      editIndex !== null
        ? prescriptionData.map((prescription, index) =>
            index === editIndex ? newPrescription : prescription
          )
        : [...prescriptionData, newPrescription]

    // If updatedPrescriptions property of drug or category is undefined, remove it
    updatedPrescriptions.forEach((prescription) => {
      if (!prescription.drug) delete prescription.drug
      if (!prescription.category) delete prescription.category
    })

    const user = getAuth().currentUser
    if (!user) return alert('Not logged in!')
    const userRef = doc(getFirestore(), 'users', user.uid)

    try {
      setPrescriptionData(updatedPrescriptions)
      console.log('updatedPrescriptions:', updatedPrescriptions)
      await updateDoc(userRef, {
        'medicalDetails.prescriptionHistory': updatedPrescriptions,
      })

      setEditIndex(null)
      onClose()

      toast({
        status: 'success',
        title: 'Saved',
        description: 'Prescription details were successfully saved!',
        isClosable: true,
      })
    } catch (error) {
      console.error('Error saving prescription:', error)
      toast({
        status: 'error',
        title: 'Error',
        description: 'An error occurred while saving prescription details.',
        isClosable: true,
      })
    }
  }

  const handleEditPrescription = (index: number) => {
    const prescription = prescriptionData[index]
    setDrug(prescription.drug || '')
    setCategory(prescription.category || '')
    setAgeStarted(prescription.ageStarted)
    setAgeStopped(prescription.ageStopped)
    setUsageType(prescription.usageType)
    setTotalYears(prescription.totalYears)
    setEditIndex(index)
    onOpen()
  }

  const handleDeletePrescription = (index: number) => {
    setDeleteIndex(index)
    onDeleteOpen()
  }

  const confirmDeletePrescription = async () => {
    if (deleteIndex !== null) {
      const updatedPrescriptions = prescriptionData.filter(
        (_, i) => i !== deleteIndex
      )
      setPrescriptionData(updatedPrescriptions)
      setDeleteIndex(null)
      onDeleteClose()

      const user = getAuth().currentUser
      if (!user) return alert('Not logged in!')
      const userRef = doc(getFirestore(), 'users', user.uid)

      try {
        await updateDoc(userRef, {
          'medicalDetails.prescriptionHistory': updatedPrescriptions,
        })
      } catch (error) {
        console.error('Error deleting prescription:', error)
      }
    }
  }

  const handleCloseModal = () => {
    setDrug('')
    setCategory('')
    setAgeStarted(null)
    setAgeStopped(null)
    setUsageType('Intermittent')
    setTotalYears(null)
    setEditIndex(null)
    onClose()
  }

  return (
    <div>
      <Box mb={4}>
        <Button rightIcon={<AddIcon />} onClick={onOpen}>
          Add Prescription
        </Button>
      </Box>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>Actions</Th>
            <Th>Prescription</Th>
            <Th>Age at which first took medication</Th>
            <Th>Age at which you stopped medication (or current)</Th>
            <Th>Intermittent or Constant</Th>
            <Th>Estimated Total Number of years</Th>
          </Tr>
        </Thead>
        <Tbody>
          {prescriptionData.map((prescription, index) => (
            <Tr key={index}>
              <Td>
                <Flex>
                  <IconButton
                    aria-label="Update"
                    size="sm"
                    mr={1}
                    onClick={() => handleEditPrescription(index)}
                    icon={<EditIcon />}
                  />
                  <IconButton
                    aria-label="Delete"
                    size="sm"
                    colorScheme="red"
                    variant="outline"
                    onClick={() => handleDeletePrescription(index)}
                    icon={<DeleteIcon />}
                  />
                </Flex>
              </Td>
              <Td>{prescription.drug || prescription.category}</Td>
              <Td>{prescription.ageStarted} years old</Td>
              <Td>{prescription.ageStopped} years old</Td>
              <Td>{prescription.usageType}</Td>
              <Td>{prescription.totalYears} years</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <PrescriptionModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        drug={drug}
        setDrug={setDrug}
        category={category}
        setCategory={setCategory}
        ageStarted={ageStarted}
        setAgeStarted={setAgeStarted}
        ageStopped={ageStopped}
        setAgeStopped={setAgeStopped}
        usageType={usageType}
        setUsageType={setUsageType}
        totalYears={totalYears}
        setTotalYears={setTotalYears}
        prescriptionList={prescriptionList}
        handleSave={handleSavePrescription}
      />
      <DeletePrescriptionDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
        onConfirm={confirmDeletePrescription}
      />
    </div>
  )
}
