import { Table, Thead, Tbody, Tr, Th } from '@chakra-ui/react'
import FirestoreUser, { AdultVaccineData } from '../../../types/FirestoreUser'
import AdultVaccineHistoryRow from './AdultVaccineHistoryRow'

const adultVaccines = [
  'Flu',
  'Shingles',
  'Covid19',
  'Pneumonia',
  'Meningitis A',
  'Meningitis B',
  'Hepatitis A',
  'Hepatitis B',
  'DPT',
  'Tetanus',
  'Malaria',
  'Dengue fever',
  'Other travel vaccines',
]

interface Props {
  uid: string
  firestoreUser: FirestoreUser
  adultVaccineHistory: AdultVaccineData[]
  setAdultVaccineHistory: (data: AdultVaccineData[]) => void
}

export default function AdultVaccineHistory({
  uid,
  firestoreUser,
  adultVaccineHistory,
  setAdultVaccineHistory,
}: Props) {
  return (
    <Table variant="simple" size="sm">
      <Thead>
        <Tr>
          <Th>Adult Vaccine History</Th>
          <Th>Had Vaccine</Th>
          <Th>Age ({'>'}18) of first vaccine</Th>
          <Th>Age of most recent vaccine</Th>
          <Th>Estimated total number of this vaccine</Th>
        </Tr>
      </Thead>
      <Tbody>
        {adultVaccines.map((vaccine) => (
          <AdultVaccineHistoryRow
            key={vaccine}
            vaccine={vaccine}
            uid={uid}
            adultVaccineHistory={adultVaccineHistory}
            setAdultVaccineHistory={setAdultVaccineHistory}
          />
        ))}
      </Tbody>
    </Table>
  )
}
