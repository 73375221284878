import { Text, Tr, Td, Checkbox, Input, Select } from '@chakra-ui/react'
import { useState, useMemo, useEffect } from 'react'
import { MedicalData } from '../../../types/FirestoreUser'

interface MedicalConditionRowProps {
  condition: string
  age: number | undefined
  uid: string
  medicalHistory: MedicalData[]
  setMedicalHistory: (data: MedicalData[]) => void
}

export default function MedicalConditionRow({
  condition,
  age,
  uid,
  medicalHistory,
  setMedicalHistory,
}: MedicalConditionRowProps) {
  const [isChecked, setIsChecked] = useState(false)
  const [frequency, setFrequency] = useState('')
  const [ageDiagnosed, setAgeDiagnosed] = useState<number | undefined>()
  const [ageEnded, setAgeEnded] = useState<number | undefined>()
  const [estimatedYears, setEstimatedYears] = useState<number | undefined>()

  useEffect(() => {
    const existingCondition = medicalHistory.find(
      (item) => item.condition === condition
    )
    if (existingCondition) {
      setIsChecked(true)
      setFrequency(existingCondition.frequency || '')
      setAgeDiagnosed(existingCondition.ageDiagnosed || undefined)
      setAgeEnded(existingCondition.ageEnded || undefined)
      setEstimatedYears(existingCondition.estimatedYears || undefined)
    } else {
      setIsChecked(false)
      setFrequency('')
      setAgeDiagnosed(undefined)
      setAgeEnded(undefined)
      setEstimatedYears(undefined)
    }
  }, [condition, medicalHistory])

  const calculatedYears = useMemo(() => {
    if (frequency === 'constant' && ageDiagnosed) {
      if (ageEnded === undefined && age !== undefined) {
        return age - ageDiagnosed
      } else if (ageEnded) {
        return ageEnded - ageDiagnosed
      }
    }
    return estimatedYears
  }, [frequency, ageDiagnosed, ageEnded, age, estimatedYears])

  useEffect(() => {
    const updatedHistory = medicalHistory.filter(
      (item) => item.condition !== condition
    )

    if (isChecked) {
      updatedHistory.push({
        id: condition,
        condition,
        ageDiagnosed: ageDiagnosed ?? null,
        ageEnded: ageEnded ?? null,
        frequency: frequency as 'intermittent' | 'constant' | null,
        estimatedYears: calculatedYears ?? null,
      })
    }

    setMedicalHistory(updatedHistory)
  }, [
    isChecked,
    ageDiagnosed,
    ageEnded,
    frequency,
    calculatedYears,
    setMedicalHistory,
    condition,
  ])

  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const checked = e.target.checked
    setIsChecked(checked)
    if (!checked) {
      setFrequency('')
      setAgeDiagnosed(undefined)
      setAgeEnded(undefined)
      setEstimatedYears(undefined)
    }
  }

  function handleFrequencyChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value
    setFrequency(value)
  }

  return (
    <Tr>
      <Td p={2}>
        <Text fontSize="sm">{condition}</Text>
      </Td>
      <Td textAlign="left" p={2}>
        <Checkbox
          name="condition"
          isChecked={isChecked}
          onChange={handleCheckboxChange}
          ml={4}
        />
      </Td>
      <Td p={2}>
        <Input
          size="sm"
          type="number"
          name="ageDiagnosed"
          placeholder="Age Diagnosed"
          isDisabled={!isChecked}
          isRequired={isChecked}
          value={ageDiagnosed ?? ''}
          onChange={(e) => {
            const value = e.target.value
            setAgeDiagnosed(value === '' ? undefined : Number(value))
          }}
        />
      </Td>
      <Td p={2}>
        <Input
          size="sm"
          type="number"
          name="ageEnded"
          placeholder="Age Ended"
          isDisabled={!isChecked}
          value={ageEnded ?? ''}
          onChange={(e) => {
            const value = e.target.value
            setAgeEnded(value === '' ? undefined : Number(value))
          }}
        />
      </Td>
      <Td p={2}>
        <Select
          size="sm"
          placeholder="Frequency"
          isDisabled={!isChecked}
          isRequired={isChecked}
          value={frequency}
          name="frequency"
          onChange={handleFrequencyChange}
        >
          <option value="intermittent">Intermittent</option>
          <option value="constant">Constant</option>
        </Select>
      </Td>
      <Td p={2}>
        <Input
          size="sm"
          type="number"
          name="estimatedYears"
          placeholder="Estimated Years"
          isDisabled={!isChecked || frequency === 'constant'}
          isRequired={
            isChecked && frequency === 'constant' && ageEnded !== undefined
          }
          value={
            frequency === 'constant' ? calculatedYears : estimatedYears ?? ''
          }
          onChange={(e) => {
            const value = e.target.value
            if (frequency !== 'constant') {
              setEstimatedYears(value === '' ? undefined : Number(value))
            }
          }}
        />
      </Td>
    </Tr>
  )
}
