import {
  Box,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  useToast,
} from '@chakra-ui/react'
import FirestoreUser, {
  MedicalData,
  InjuryData,
  SurgeryData,
  ChildhoodVaccineData,
  AdultVaccineData,
} from '../../types/FirestoreUser'
import MedicalConditions from './MedicalConditions'
import usePurpleBubblesText from '../../hooks/usePurpleBubblesText'
import PurpleBubbleContent from '../../components/PurpleBubbleContent'
import InjuryHistory from './InjuryHistory'
import SurgeryHistory from './SurgeryHistory'
import PrescriptionHistory from './PrescriptionHistory'
import ChildhoodVaccineHistory from './ChildhoodVaccineHistory'
import AdultVaccineHistory from './AdultVaccineHistory'
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { useState, useEffect } from 'react'
import isEqual from 'lodash/isEqual'
import HistoryAccordionItem from './HistoryAccordionItem'

interface Props {
  uid: string
  firestoreUser: FirestoreUser
}

function ProfileMedicalHistory({ uid, firestoreUser }: Props) {
  const [medicalHistory, setMedicalHistory] = useState<MedicalData[]>([])
  const [initialMedicalHistory, setInitialMedicalHistory] = useState<
    MedicalData[]
  >([])
  const [injuryHistory, setInjuryHistory] = useState<InjuryData[]>([])
  const [initialInjuryHistory, setInitialInjuryHistory] = useState<
    InjuryData[]
  >([])
  const [surgeryHistory, setSurgeryHistory] = useState<SurgeryData[]>([])
  const [initialSurgeryHistory, setInitialSurgeryHistory] = useState<
    SurgeryData[]
  >([])
  const [childhoodVaccineHistory, setChildhoodVaccineHistory] = useState<
    ChildhoodVaccineData[]
  >([])
  const [initialChildhoodVaccineHistory, setInitialChildhoodVaccineHistory] =
    useState<ChildhoodVaccineData[]>([])
  const [adultVaccineHistory, setAdultVaccineHistory] = useState<
    AdultVaccineData[]
  >([])
  const [initialAdultVaccineHistory, setInitialAdultVaccineHistory] = useState<
    AdultVaccineData[]
  >([])

  const [isSaving, setIsSaving] = useState(false)
  const purpleBubbleContent = usePurpleBubblesText('Profile-step-3')
  const toast = useToast()

  useEffect(() => {
    const fetchMedicalHistory = async () => {
      const user = getAuth().currentUser
      if (!user) return alert('Not logged in!')
      const userRef = doc(getFirestore(), 'users', user.uid)
      const userDoc = await getDoc(userRef)
      if (userDoc.exists()) {
        const data = userDoc.data()
        const fetchedMedicalHistory = data.medicalDetails?.medicalHistory || []
        const fetchedInjuryHistory = data.medicalDetails?.injuryHistory || []
        const fetchedSurgeryHistory = data.medicalDetails?.surgeryHistory || []
        const fetchedChildhoodVaccineHistory =
          data.medicalDetails?.childhoodVaccineHistory || []
        const fetchedAdultVaccineHistory =
          data.medicalDetails?.adultVaccineHistory || []
        setMedicalHistory(fetchedMedicalHistory)
        setInitialMedicalHistory(fetchedMedicalHistory)
        setInjuryHistory(fetchedInjuryHistory)
        setInitialInjuryHistory(fetchedInjuryHistory)
        setSurgeryHistory(fetchedSurgeryHistory)
        setInitialSurgeryHistory(fetchedSurgeryHistory)
        setChildhoodVaccineHistory(fetchedChildhoodVaccineHistory)
        setInitialChildhoodVaccineHistory(fetchedChildhoodVaccineHistory)
        setAdultVaccineHistory(fetchedAdultVaccineHistory)
        setInitialAdultVaccineHistory(fetchedAdultVaccineHistory)
      }
    }
    fetchMedicalHistory()
  }, [])

  const saveHistory = async (historyData: any[], fieldName: string) => {
    console.log('Function executing')
    setIsSaving(true)
    const user = getAuth().currentUser
    if (!user) {
      alert('Not logged in!')
      setIsSaving(false)
      return
    }
    const userRef = doc(getFirestore(), 'users', user.uid)
    await updateDoc(userRef, {
      [`medicalDetails.${fieldName}`]: historyData,
    })
    toast({
      title: 'Success',
      description: `Your ${fieldName.replace(
        /([A-Z])/g,
        ' $1'
      )} data has been successfully saved.`,
      status: 'success',
      duration: 5000,
      isClosable: true,
    })
    setIsSaving(false)
  }

  const saveMedicalHistory = () => saveHistory(medicalHistory, 'medicalHistory')
  const saveInjuryHistory = () => saveHistory(injuryHistory, 'injuryHistory')
  const saveSurgeryHistory = () => saveHistory(surgeryHistory, 'surgeryHistory')
  const saveChildhoodVaccineHistory = () =>
    saveHistory(childhoodVaccineHistory, 'childhoodVaccineHistory')
  const saveAdultVaccineHistory = () =>
    saveHistory(adultVaccineHistory, 'adultVaccineHistory')

  return (
    <>
      <form>
        <Stack spacing={5}>
          {purpleBubbleContent?.text && (
            <PurpleBubbleContent text={purpleBubbleContent.text} />
          )}
          <Accordion defaultIndex={[]} allowToggle>
            <HistoryAccordionItem
              title="Medical History"
              isSaving={isSaving}
              onSave={() => {
                if (!isEqual(medicalHistory, initialMedicalHistory)) {
                  saveMedicalHistory()
                }
              }}
            >
              <MedicalConditions
                uid={uid}
                firestoreUser={firestoreUser}
                medicalHistory={medicalHistory}
                setMedicalHistory={setMedicalHistory}
              />
            </HistoryAccordionItem>
            <HistoryAccordionItem
              title="Injury History"
              isSaving={isSaving}
              onSave={() => {
                if (!isEqual(injuryHistory, initialInjuryHistory)) {
                  saveInjuryHistory()
                }
              }}
            >
              <InjuryHistory
                uid={uid}
                firestoreUser={firestoreUser}
                injuryHistory={injuryHistory}
                setInjuryHistory={setInjuryHistory}
              />
            </HistoryAccordionItem>
            <HistoryAccordionItem
              title="Surgery History"
              isSaving={isSaving}
              onSave={() => {
                if (!isEqual(surgeryHistory, initialSurgeryHistory)) {
                  saveSurgeryHistory()
                }
              }}
            >
              <SurgeryHistory
                uid={uid}
                firestoreUser={firestoreUser}
                surgeryHistory={surgeryHistory}
                setSurgeryHistory={setSurgeryHistory}
              />
            </HistoryAccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Heading as="h2" size="md">
                    Prescription Drug History
                  </Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <PrescriptionHistory uid={uid} firestoreUser={firestoreUser} />
              </AccordionPanel>
            </AccordionItem>
            <HistoryAccordionItem
              title="Childhood Vaccine History"
              isSaving={isSaving}
              onSave={() => {
                if (
                  !isEqual(
                    childhoodVaccineHistory,
                    initialChildhoodVaccineHistory
                  )
                ) {
                  saveChildhoodVaccineHistory()
                }
              }}
            >
              <ChildhoodVaccineHistory
                uid={uid}
                firestoreUser={firestoreUser}
                childhoodVaccineHistory={childhoodVaccineHistory}
                setChildhoodVaccineHistory={setChildhoodVaccineHistory}
              />
            </HistoryAccordionItem>
            <HistoryAccordionItem
              title="Adult Vaccine History"
              isSaving={isSaving}
              onSave={() => {
                if (!isEqual(adultVaccineHistory, initialAdultVaccineHistory)) {
                  saveAdultVaccineHistory()
                }
              }}
            >
              <AdultVaccineHistory
                uid={uid}
                firestoreUser={firestoreUser}
                adultVaccineHistory={adultVaccineHistory}
                setAdultVaccineHistory={setAdultVaccineHistory}
              />
            </HistoryAccordionItem>
          </Accordion>
        </Stack>
      </form>
    </>
  )
}

export default ProfileMedicalHistory
